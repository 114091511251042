<template>
    <div class="Milestone" v-if="updata">
        <div class="Milestone_data">
            <h1>{{updata.cardTitle}}</h1>
            <div class="Milestone_content">
                <div class="block">
                    <div class="block_list">
                        <div class="block_content" v-for="(item, index) in updata.tbCardListModelList" :key="index">
                            <div class="block_card">
                                <p v-html="item.cardContent"></p>
                                <img :src="item.cardImage" alt="">
                                <span>{{item.cardTitle}}</span>
                            </div>
                        </div>
                        <div class="block_cardTitle">{{downdata.cardTitle}}</div>
                    </div>
                    <div class="block_line"></div>
                    <div class="block_methods">
                        <div class="block_setion" v-for="(item, k) in downdata.tbCardListModelList" :key="k">
                            <div class="block_bottom">
                                <span>{{item.cardTitle}}</span>
                                <img :src="item.cardImage" alt="">
                                <p v-html="item.cardContent"></p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { getcardFesc } from '@/api/commonApi'
export default {
    name: "Milestone",
    props: {
        demo: {
			type: String,
            default: '1',
            require: true
		}
	},
    data() {
        return {
            showbottom: false,
            downdata: null,
            updata: null
        }
    },
    created() {
        this.getFesc()
    },
    methods: {
        getFesc() {
            getcardFesc().then(([res]) => {
				if (res.success) {
                    this.downdata = res.data[1]
					this.updata = res.data[0]
				}
			})
        }
    }
}
</script>
<style lang="less" scoped>
.Milestone {
    background: #FAFDFD;
    padding-top: 80px;
    width: 100%;
    height: auto;
    padding-bottom: 80px;
    .Milestone_data {
        width: 1400px;
        margin: 0px auto;
        h1 {
            font-weight: 400;
            font-size: 24px;
            line-height: 38px;
            text-align: center;
            color: #040B29;
        }
        .Milestone_content {
            width: 100%;
            margin-top: 60px;
        }
    }
}
.block_content {
    width: 152px;
    display: inline-block;
    margin-right: 152px;
}
.block_content:nth-child(5){
    width: 152px;
    display: inline-block;
    margin-right: 0px;
}
.block_methods {
    display: flex;
}
.block_list {
    position: relative;
}
.block_cardTitle {
    position: absolute;
    right:17px;
    bottom: -30px;
}
.block_setion {
    width: 152px;
    display: inline-block;
    margin-left: 152px;
}
.block_line {
    width: 100%;
    height: 2px;
    background: #00BEBE;
    position: relative;
}
.block_line:before{
    content:  '';
    position: absolute;
    height: 4px;
    width: 4px;
    right: -1px;
    top: -1.5px;
    border-width: 0 1px 1px 0;
    border-style: solid;
    color: #00BEBE;
    /* 根据这个来控制箭头方向 */
    transform: rotate(-45deg);
}
.block_bottom {
    width: 152px;
    img {
        margin-top: 8px;
        width: 152px;
        height: 108px;
        object-fit: cover;
    }
    p {
        margin-top: 8px;
        font-weight: 400;
        font-size: 12px;
        width: 156px;
        text-align: justify;
        height: auto;
        overflow: hidden;
        line-height: 20px;
        color: #6F7385;
    }
    span {
        display: inline-block;
        margin-top: 8px;
        font-weight: 400;
        font-size: 16px;
        line-height: 25px;
        color: #41475E;
        position: relative;   
    }
    span:before{
        content:  '';
        position: absolute;
        height: 8px;
        width: 8px;
        left: 0px;
        top: -13px;
        border-radius: 50%;
        background: #00BEBE;
    }
}
.block_card {
    width: 152px;
    img {
        width: 152px;
        height: 108px;
    }
    p {
        margin-bottom: 10px;
        font-weight: 400;
        width: 152px;
        height: auto;
        overflow: hidden;
        font-size: 12px;
        line-height: 20px;
        color: #6F7385;
    }
    span {
        display: inline-block;
        margin-bottom: 8px;
        font-weight: 400;
        font-size: 16px;
        line-height: 25px;
        color: #41475E;
        position: relative;   
    }
    span:before{
        content:  '';
        position: absolute;
        height: 8px;
        width: 8px;
        left: 0px;
        top: 30px;
        border-radius: 50%;
        background: #00BEBE;
    }
}
// /deep/ .el-timeline {
//     display: flex;
// }
// /deep/ .el-timeline-item__tail {
//     width: 202px;
//     height: 2px;
//     background: #00BEBE;
//     top: 140px;
// }
// /deep/ .el-timeline-item__node--normal {
//     top: 135px;
// }
// /deep/ .el-timeline-item__wrapper {
//     padding: 0px;
// }
// /deep/.el-card__body {
//     padding: 0px;
// }
// /deep/ .el-timeline-item__timestamp.is-top {
//     position: absolute;
//     top: 110px;
// }
</style>