<template>
  <div class="first-screen">
    <video
      @playing.once="playing = true"
      ref="video"
      v-if="data && data.cardVideo"
      class="abs"
      :src="data && data.cardVideo"
      autoplay
      loop
      muted
      preload
    ></video>
    <video
      @playing.once="playing = true"
      ref="video"
      v-else
      class="abs"
      :src="data && data.videoUrl"
      autoplay
      loop
      muted
      preload
    ></video>
    <div
      v-if="data && data.cardVideo"
      class="content-wrap pr flex-center tc"
      :class="{ 'show-bg': !playing }"
    >
      <div>
        <div class="titles">
          <div class="slogan">{{ data.cardTitle }}</div>
          <div class="subtitle">{{ data.cardContent }}</div>
          <div class="apply-btns" v-if="data.linkUrl">
            <a :href="data.linkUrl" target="_blank">立即投递3</a>
          </div>
        </div>
      </div>
    </div>
    <div
      v-else
      class="content-wrap pr flex-center tc"
      :class="{ 'show-bg': !playing }"
    >
      <div>
        <div class="titles" v-if="data">
          <div class="slogan">{{ data.videoName }}</div>
          <div class="subtitle">{{ data.videoWordOne }}</div>
        </div>
      </div>
    </div>
    <div class="tap-wrap pr tc">
      <span class="anchor-wrap cursor" @click="scrollToAnchor"><a></a></span>
    </div>
    <div ref="anchor" class="scroll-anchor abs"></div>
  </div>
</template>

<script>
export default {
  props: {
    data: {
      type: Object,
      default: null,
      require: true,
    },
  },
  data() {
    return {
      playing: false,
    };
  },
  created() {
    // console.log("DATA:", this.data);
  },
  methods: {
    scrollToAnchor() {
      // this.$refs['anchor'].scrollIntoView({ behavior: 'smooth' })
      this.$smoothDown(this.$refs["anchor"], 100);
    },
  },
};
</script>

<style scoped lang="less">
.scroll-anchor {
  bottom: -1px;
}
@keyframes jump {
  0% {
    padding-top: 2px;
  }
  50% {
    padding-top: 9px;
  }
  100% {
    padding-top: 2px;
  }
}
.first-screen {
  height: 100vh; //900px改为100vh 0727echo
  position: relative;
  video {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center center;
    left: 0;
    top: 0;
  }
  .show-bg {
    background-color: rgba(156, 198, 207, 0.75) !important;
  }
  .content-wrap {
    width: 100%;
    height: 100%;
    z-index: 1;
    // background: rgba(19, 60, 154, 0.2);
    .titles {
      height: 125px;
    }
    .slogan {
      font-size: 56px;
      color: #fff;
      line-height: 82px;
      letter-spacing: 7px;
      font-weight: 100;
    }
    .subtitle {
      margin-top: 8px;
      font-size: 20px;
      font-weight: 400;
      color: #ffffff;
      line-height: 32px;
      letter-spacing: 2px;
    }
    .apply-btns {
      display: flex;
      justify-content: center;

      margin: 20px auto 0;
      width: 476px;

      a {
        display: flex;
        justify-content: center;
        align-items: center;

        width: 222px;
        height: 48px;
        // line-height: 48px;
        // text-align: center;
        border-radius: 2px;
        background-color: transparent;
        transition: background-color 0.3s;

        &:first-child {
          background-color: #fff;
          color: #00b3be;
          &:hover {
            background-color: #00b3be;
            color: #fff;
          }
        }
        &:last-child {
          line-height: 46px;
          background-color: rgba(255, 255, 255, 0.1);
          color: #ffffff;
          &:hover {
            border: 1px solid #00b3be;
            background-color: #00b3be;
            color: #fff;
          }
        }
      }
    }
  }
  .tap-wrap {
    bottom: 15%;
    z-index: 2;
    .anchor-wrap {
      display: inline-block;
      width: 30px;
      height: 30px;
      // border: 1px solid #00b3be;
      // border-radius: 50%;
      animation: jump 1.5s linear infinite;
    }
    a {
      display: inline-block;
      width: 24px;
      height: 29px;
      background: url("~@/assets/img/slide-down.png") center no-repeat;
      background-size: contain;
    }
  }
}
</style>
