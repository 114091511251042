<template>
  <div class="talent-container">
    <!-- 顶部banner图 -->
    <!-- <div v-if="telentProjectData" class="talent-banner" :style="{'background-image': 'url(' + telentProjectData.moduleImage + ')'}"> -->
    <!-- <div class="talent-banner">
            <img :src="telentProjectData.moduleImage" alt="">
        </div> -->
    <VideoBanner :data="list.formula"></VideoBanner>
    <!-- 标题 -->
    <!--		<div class="banner" v-if="list">-->
    <!--			<img :src="list.formula.cardImage" alt="" />-->
    <!--			<div class="talent-content">-->
    <!--				<h5 class="talent-title">{{ list.formula.cardTitle }}</h5>-->
    <!--				<p class="project-desc">-->
    <!--					{{ list.formula.cardContent }}-->
    <!--				</p>-->
    <!--				&lt;!&ndash; <h5 class="modules-title pd-24">你将体验</h5> &ndash;&gt;-->
    <!--				&lt;!&ndash; 你将体验 &ndash;&gt;-->
    <!--				&lt;!&ndash; <ProjectFetures :data="telentProjectData.features"></ProjectFetures> &ndash;&gt;-->
    <!--				&lt;!&ndash; <h5 class="modules-title">投递要求</h5>-->
    <!--                <DeliveryRequire :data="telentProjectData.deliverAsks"></DeliveryRequire> &ndash;&gt;-->
    <!--				&lt;!&ndash; <p class="modules-title">了解详情</p> &ndash;&gt;-->
    <!--				&lt;!&ndash; <div class="margin-bottom:100px;">-->
    <!--                    <Win :data="telentProjectData.flows" :mBtm="100"></Win>-->
    <!--                </div> &ndash;&gt;-->
    <!--			</div>-->
    <!--			<div class="tap-wrap pr tc">-->
    <!--				<span class="anchor-wrap cursor" @click="scrollToAnchor"-->
    <!--					><a></a-->
    <!--				></span>-->
    <!--			</div>-->
    <!--			<div ref="anchor" class="scroll-anchor abs"></div>-->
    <!--		</div>-->
    <!--方程式提前批校招-->
    <div class="recruitment">
      <div class="recruitment_content">
        <h2>{{ list.formuladesc.cardTitle }}</h2>
        <p v-html="list.formuladesc.cardContent"></p>
        <span @click="setcar(list.formuladesc)">了解更多</span>
      </div>
      <div class="recruitment_list">
        <ul>
          <li v-for="(item, index) in list.formula.tbCardListModelList" :key="index">
            <div class="linkurl">
              <img :src="item.cardImage" alt="" />
            </div>
            <h5>{{ item.cardTitle }}</h5>
            <p v-html="item.cardContent"></p>
          </li>
        </ul>
      </div>
    </div>
    <!--赛事简介-->
    <div class="briefintroduction">
      <div class="brief_content">
        <div class="brief_list" v-for="(item, k) in list.formuladesc.tbCardListModelList" :key="k">
          <div class="brief_left">
            <el-image :src="item.cardImage">
              <div slot="placeholder" class="image-holder"></div>
            </el-image>
            <!-- <img src="../../assets/img/talent/image48.png" alt=""> -->
          </div>
          <div class="brief_right">
            <h5>{{ item.cardTitle }}</h5>
            <p v-html="item.cardContent"></p>
          </div>
        </div>
      </div>
    </div>
    <!--FESC Milestone-->
    <Milestone></Milestone>
    <!-- <div class="Milestone">
            <h1>FESC Milestone</h1>
            <div class="Milestone_content">

            </div>
        </div> -->
    <talk :data-id="dataId"></talk>
  </div>
</template>
<script>
import { getTelentProject, getcardList } from "@/api/commonApi";
import VideoBanner from "./VideoBanner";
import ProjectFetures from "./ProjectFetures";
import DeliveryRequire from "./DeliveryRequire";
import Milestone from "./Milestone";
import Win from "@/components/Win";
import Talk from "../home/Talk";
import { setPreview } from "../../utils/common";

export default {
  components: {
    ProjectFetures,
    DeliveryRequire,
    VideoBanner,
    Win,
    Milestone,
    Talk,
  },
  data() {
    return {
      // telentProjectData: {
      //     moduleame: "蔚来2023届方程式提前批校招",
      //     moduleFlowContent: "加入蔚来，延续对速度与梦想的热爱。"
      // },
      // tableData: {
      //     cardTitle: null,
      //     cardContent:null,
      //     tbCardListModelList: null,
      // },
      list: {
        formuladesc: {},
        formula: {
          cardImage: null,
        },
      },
      dataId: "1",
      equation: true,
      loading: true,
    };
  },
  created() {
    this.handleCreatedFunc();
  },
  methods: {
    scrollToAnchor() {
      // this.$refs['anchor'].scrollIntoView({ behavior: 'smooth' })
      this.$smoothDown(this.$refs["anchor"], 100);
    },
    // 点击跳转大学生公告的详情
    setcar(item) {
      window.open(item.detailsUrl);
    },
    handleCreatedFunc() {
      getTelentProject(1).then(([res]) => {
        if (res.success) {
          this.telentProjectData = res.data;
          this.loading = false;
        }
      });
      // equatTion().then(([res]) => {
      //     if (res.success) {
      //         this.tableData = res.data[0]
      //     }
      // })
      getcardList(["formula", "formuladesc", "formulaSeniorSay"]).then(
        ([res]) => {
          if (res.success) {
            this.list = res.data;
          }
        }
      );
    },
  },
};
</script>

<style lang="less" scoped>
.scroll-anchor {
  bottom: -1px;
}

@keyframes jump {
  0% {
    padding-top: 2px;
  }

  50% {
    padding-top: 9px;
  }

  100% {
    padding-top: 2px;
  }
}

.tap-wrap {
  bottom: 15%;
  z-index: 2;

  .anchor-wrap {
    display: inline-block;
    width: 30px;
    height: 30px;
    // border: 1px solid #00b3be;
    // border-radius: 50%;
    animation: jump 1.5s linear infinite;
  }

  a {
    display: inline-block;
    width: 24px;
    height: 29px;
    background: url("~@/assets/img/slide-down.png") center no-repeat;
    background-size: contain;
  }
}

// 媒体查询
// @media screen and (max-width: 960px) {
// }

// @media screen and (max-width: 768px) {
// }

// @media screen and (max-width: 550px) {
// }

// @media screen and (max-width: 320px) {
// }
.banner {
  width: 100%;
  height: 900px;
  background-size: 100% 100%;
  z-index: 1;
  position: relative;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    z-index: -1;
  }
}

.talent-container {
  overflow: hidden;
  width: 100%;
  min-height: calc(100% - 346px);
  background-color: #ffffff;
  position: relative;
  top: -64px;

  .talent-banner {
    width: 100%;
    height: 352px;
    background-color: #ccc;
    background-image: url("../../assets/img/banner.png");
    background-position: center;
    background-size: cover;
  }

  .talent-content {
    margin: 0 auto;
    width: 996px;
    height: 857px;
    text-align: center;

    .talent-title {
      padding-top: 238px;
      font-size: 56px;
      font-weight: 700;
      line-height: 81px;
      color: rgba(255, 255, 255, 1);
      letter-spacing: 0.14em;
    }

    .pd-24 {
      padding-bottom: 24px;
    }

    .modules-title {
      margin: 0px auto;
      margin-top: 72px; // 0 24px;
      box-sizing: border-box;
      width: 222px;
      height: 48px;
      cursor: pointer;
      background: #ffffff;
      color: rgba(0, 179, 190, 1);
      font-size: 14px;
      line-height: 48px;
      text-align: center;
      font-weight: 400;
      border-radius: 2px;
      z-index: 99999;
    }

    .project-desc {
      margin: 0 auto;
      padding-top: 8px;
      font-weight: 400;
      font-size: 20px;
      line-height: 32px;
      text-align: center;
      letter-spacing: 0.14em;
      color: #ffffff;
    }
  }
}

.talent-container .talent-content .modules-title:hover {
  background: #00b3be;
  color: #fff;
}

.recruitment .recruitment_content span:hover {
  background: #00b3be;
  color: #fff;
}

.recruitment {
  width: 1200px;
  height: 384px;
  margin: 80px auto;
  padding-top: 80px;

  .recruitment_content {
    display: inline-block;
    float: left;

    h2 {
      font-weight: 400;
      font-size: 24px;
      line-height: 38px;
      color: #040b29;
    }

    p {
      padding-top: 16px;
      width: 468px;
      height: auto;
      display: -webkit-box;
      /*将对象转为弹性盒模型展示*/
      -webkit-box-orient: vertical;
      /*设置弹性盒模型子元素的排列方式*/
      -webkit-line-clamp: 3;
      /*限制文本行数*/
      overflow: hidden;
      /*超出隐藏*/
      font-weight: 400;
      font-size: 16px;
      line-height: 28px;
      color: #41475e;
    }

    span {
      margin-top: 24px;
      width: 120px;
      height: 32px;
      cursor: pointer;
      display: inline-block;
      text-align: center;
      border: 1px solid #00b3be;
      border-radius: 2px;
      font-weight: 400;
      font-size: 12px;
      line-height: 32px;
      color: #00b3be;
    }
  }

  .recruitment_list {
    display: inline-block;
    margin-left: 85px;

    ul {
      display: inline-block;

      li {
        width: 183px;
        margin-right: 48px;
        float: left;
        text-align: center;

        .linkurl {
          width: 120px;
          height: 120px;
          border-radius: 50%;
          overflow: hidden;
          margin: 0px auto;

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }

        h5 {
          margin-top: 20px;
          font-weight: 400;
          font-size: 14px;
          line-height: 22px;
          text-align: center;
          color: #040b29;
        }

        p {
          padding-top: 3px;
          font-weight: 400;
          font-size: 12px;
          line-height: 19px;
          color: #6f7385;
        }
      }

      li:nth-child(3) {
        margin-right: 0px;
      }
    }
  }
}

.briefintroduction {
  width: 1200px;
  height: 390px;
  margin: 0px auto;
  margin-top: 80px;
  margin-bottom: 80px;
}

.brief_content {
  width: 100%;
  height: 390px;

  .brief_list {
    width: 100%;
    height: 100%;

    .brief_left {
      width: 692px;
      height: 390px;
      border-radius: 4px;
      overflow: hidden;
      display: inline-block;
      float: left;
      margin-right: 40px;

      .el-image {
        width: 692px;
        height: 390px;

        /deep/ img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }

        .image-holder {
          height: 100%;
          background: rgba(156, 198, 207, 0.39) url("~@/assets/img/image-holder.png") center no-repeat;
          background-size: 100% 100%;
        }
      }

      img {
        width: 100%;
        height: 100%;
      }
    }

    .brief_left.two {
      margin-left: 40px;
      margin-right: 0px;
    }

    .brief_right {
      display: inline-block;
      float: left;
      width: 460px;
      height: 390px;

      h5 {
        margin-top: 37px;
        font-weight: 400;
        font-size: 24px;
        line-height: 38px;
        color: #040b29;
      }

      p {
        width: 468px;
        margin-top: 20px;
        font-weight: 400;
        font-size: 16px;
        line-height: 28px;
        color: #41475e;
      }
    }
  }
}
</style>