<template>
    <ul class="talent-require">
        <li class="require-box" v-for="(item, index) in data" :key="index">
            <div class="require-img">
                <img :src="item.askImage" alt="">
            </div>
            <p class="require-title">{{item.askTitle}}</p>
            <p class="require-detail" v-html="item.askContent"></p>
        </li>
    </ul>
</template>
<script>
export default {
    props: {
        data: {
            type: Array,
            require: true,
            default: []
        }
    }
}
</script>
<style lang="less" scoped>
.talent-require {
    display: flex;
    justify-content: space-between;
    margin: 0 auto;
    // padding-bottom: 60px;
    width: 984px;
    .require-box {
        padding: 24px 0 0;
        width: 240px;
        text-align: center;
        .require-img {
            img {
                width: 240px;
                height: 240px;
                object-fit: cover;
            }
        }
        .require-title {
            padding: 20px 0 8px;
            font-size: 16px;
            font-weight: 500;
            color: #040b29;
        }
        .require-detail {
            font-size: 12px;
            font-weight: 400;
            line-height: 20px;
            color: #41475e;
        }
    }
}
</style>