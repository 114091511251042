<template>
    <ul class="project-features">
        <li class="project-features-box" :class="{'justy-center': data.length===1}"
            v-for="(item, index) in data" :key="index">
            <div class="project-features-desc">
            <!--<img :src="item.featureImage" alt="">-->
                <div class="pic-wrap">
                    <el-image :src="item.featureImage" class="ieReset">
                        <div slot="placeholder" class="image-holder"></div>
                    </el-image>
                </div>
            </div>
            <p class="project-features-title">{{item.featureTitle}}</p>
            <p class="project-features-content">{{item.featureContent}}</p>
        </li>
    </ul>
</template>
<script>
export default {
    props: {
        data: {
            type: Array,
            require: true,
            default: []
        }
    }
}
</script>
<style lang="less" scoped>
.project-features {
    display: flex;
    justify-content: space-between;
    text-align: left;
    .project-features-box {
        padding-bottom: 0; //60px;
        width: 180px;
        .project-features-desc {
            .ieReset {
                /deep/ .el-image__inner--center {
                    top: 0;
                    left: 0;
                    transform: none;
                }
            }
            img {
                width: 180px;
                height: 180px;
                object-fit: cover;
            }
        }
        .project-features-title {
            padding: 20px 0 8px;
            font-size: 14px;
            font-weight: 500;
            text-align: center;
            color: #040b29;
        }
        .project-features-content {
            display: -webkit-box;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
            overflow: hidden;
            font-size: 12px;
            font-weight: 400;
            line-height: 18px;
            text-overflow: ellipsis;
            color: rgba(4, 11, 41, 0.5);
            text-align: center;
        }
    }
}
</style>